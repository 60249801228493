<template>
  <validation-provider :name="label" :rules="rules" v-slot="validationContext">
    <b-form-group :label="label" :label-for="id">
      <b-form-textarea :name="id" rows="3" :size="size" :aria-describedby="`${id}-feedback`" :type="type" :disabled="disabled" :readonly="readonly" :id="id" :state="getValidationState(validationContext)" @input="handleInput" :value="value"></b-form-textarea>
      <b-form-invalid-feedback :id="`${id}-feedback`">{{validationContext.errors[0]}}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import id from "vee-validate/dist/locale/id.json"
import { localize, extend, ValidationProvider } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";
import {BFormGroup,BFormTextarea,BFormInvalidFeedback} from 'bootstrap-vue'
import _ from 'lodash'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('id', id)

export default {
  props:{
    label:{
      required: true,
      type: String
    },
    type:{
      required: false,
      type: String
    },
    size:{
      required: false,
      type: String
    },
    value:{
      required: false,
      type: [String,Number]
    },
    rules:{
      required: false,
      type: [Object,String]
    },
    readonly:{
      type: Boolean,
      default: false
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  computed:{
    id(){
      return _.camelCase(this.label)
    }
  },
  components:{
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    BFormInvalidFeedback,
  },
  methods:{
    handleInput(e){
      this.$emit('input', e)
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? (valid ? null : false) : null;
    },
  }
}
</script>

<style>

</style>