<template>
  <b-overlay :show="isloading" rounded spinner-small spinner-variant="primary" class="d-inline-block">
    <b-button type="submit" @click="actionsave" variant="primary">
      <feather-icon :icon="icon"></feather-icon> {{text}}
    </b-button>
  </b-overlay>
</template>

<script>
import {BButton,BOverlay} from 'bootstrap-vue'
export default {
  props:{
    to:{
      required: false,
      type: [String,Object],
    },
    isloading:{
      required: false,
      default: false
    },
    text:{
      type:String,
      default:"Simpan"
    },
    icon:{
      type:String,
      default:"SaveIcon"
    }
  },
  name:'ButtonSave',
  components:{
    BButton,
    BOverlay
  },
  methods:{
    actionsave(){
      this.$emit('save',null)
    }
  }
}
</script>

<style>

</style>