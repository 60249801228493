<template>
  <validation-observer ref="observer">
    <b-form @submit.prevent="onSubmit">
      <slot></slot>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { BForm } from 'bootstrap-vue'

export default {
  name:'FormValidation',
  components:{
    ValidationObserver,
    BForm
  },
  methods:{
    onSubmit(e){
      this.$refs.observer.validate().then(success => {
        if(success) {
          this.$emit('submit', e)
        }
      })
    }
  }
}
</script>

<style>

</style>