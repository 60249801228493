<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card :isloading="cardloading" :title="cardtitle">
        <template slot="body">
          <form-validation @submit="submitForm">
            <b-row>
              <b-col sm="12">
                <form-select :disabled="!isPusat||formStatus==2" ref="branchSelect" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" @value-changed="branch_name = $event.label" :rules="{required: true}" v-model="id_branch" label="Cabang"></form-select>
              </b-col>
              <b-col sm="12" md="8">
                <form-date :disabled="!cP(78)||formStatus==2" :rules="{required: true}" v-model="date" label="Tanggal"></form-date>
              </b-col>
              <b-col sm="12">
                <form-select :disabled="formStatus==2" ref="itemSelect" url="v1/item_select2" @value-changed="name_item = $event.label;id_unit=null;name_unit=''" :rules="{required: true}" v-model="id_item" label="Item"></form-select>
              </b-col>
              <b-col sm="12" md="6">
                <form-input-number :disabled="formStatus==2" :rules="{required:true}" v-model="qty" label="Qty"></form-input-number>
              </b-col>
              <b-col sm="12" md="6">
                <form-select :disabled="formStatus==2" ref="unitSelect" url="v1/multi_unit_select2" @value-changed="name_unit = $event.label" :queryparams="{id_item:id_item}" v-model="id_unit" label="Satuan"></form-select>
              </b-col>
              <b-col sm="12" md="8">
                <form-input :disabled="formStatus==2" :rules="{required: false}" v-model="reff_code" label="Kode Reff"></form-input>
              </b-col>
              <b-col sm="12">
                <form-textarea :disabled="formStatus==2" :rules="{required: false}" v-model="note" label="Catatan"></form-textarea>
              </b-col>
              <b-col sm="12" class="mt-3" v-if="formStatus!=2">
                <button-save :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'openingbalances'}"></button-back>
              </b-col>
            </b-row>
          </form-validation>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import FormTextarea from '@/my-components/FormTextarea.vue'
import FormDate from '@/my-components/FormDate.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import FormInput from '@/my-components/FormInput.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'

export default {
  components:{
    FormDate,
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormSelect,
    FormInputNumber,
    FormTextarea,
  },
  data(){
    return {
      isloading: false,
      cardloading: false,
      id: this.$route.params.id||null,
      cardtitle: "",
      code: "",
      id_branch: "",
      branch_name: "",
      date: this.$moment().format(),
      id_item: "",
      name_item: "",
      qty: 1,
      id_unit: "",
      name_unit: "",
      reff_code: "",
      note: ""
    }
  },
  computed:{
    formStatus(){
      const meta = this.$route.meta
      if(meta.isEdit) return 1
      else if(meta.isShow) return 2
      else return 0
    },
  },
  methods:{
    ...mapActions({
      dispatchStore: 'openingbalances/store',
      dispatchShow: 'openingbalances/show',
      dispatchUpdate: 'openingbalances/update',
    }),
    firstInit(){
      const defaults = this.$store.state.auth
      this.$refs.branchSelect.initOption(defaults.defaultbranches)
      this.id_branch = defaults.profile.id_branch
      this.branch_name = defaults.profile.branch_name
    },
    async getData(){
      this.cardloading = true
      const data = await this.dispatchShow(this.id)
      this.cardtitle = data.code
      this.code = data.code
      this.id_branch = data.id_branch
      this.branch_name = data.branch_name
      this.date = data.date
      this.id_item = data.id_item
      this.name_item = data.name_item
      this.qty = data.qty
      this.id_unit = data.id_unit
      this.name_unit = data.name_unit
      this.reff_code = data.reff_code
      this.note = data.note
      if(data.id_branch) this.$refs.branchSelect.initOption([{value:data.id_branch,label:data.branch_name}])
      if(data.id_item) this.$refs.itemSelect.initOption([{value:data.id_item,label:data.name_item}])
      if(data.id_unit) this.$refs.unitSelect.initOption([{value:data.id_unit,label:data.name_unit}])
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,id_branch,branch_name,date,id_item,name_item,qty,id_unit,name_unit,reff_code,note,code} = this
      let params = {id,id_branch,branch_name,date,id_item,name_item,qty,id_unit,name_unit,reff_code,note,code}
      try {
        if (this.id) await this.dispatchUpdate(params);
        else await this.dispatchStore(params);
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'openingbalances'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
    inputed(e){
      this.qty = e
    }
  },
  mounted(){
    if(this.id) return this.getData()
    else this.firstInit()
  }
}
</script>

<style>

</style>