<template>
  <b-button :block="block" :to="to" variant="outline-primary">
    <feather-icon icon="ChevronLeftIcon"></feather-icon> Kembali
  </b-button>
</template>

<script>
import {BButton} from 'bootstrap-vue'
export default {
  props:{
    to:{
      required: false,
      type: [String,Object],
    },
    block:{
      type: Boolean,
      default: false
    }
  },
  name:'ButtonBack',
  components:{
    BButton
  }
}
</script>

<style>

</style>