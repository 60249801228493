<template>
  <validation-provider :name="label||placeholder" :rules="rules" v-slot="validationContext" :vid="vid">
    <b-form-group class="padding-small" :label="label" :label-for="id">
      <b-form-input :name="id" :size="size" :autocomplete="type=='password' ? 'new-password':null" :aria-describedby="`${id}-feedback`" :type="type" :disabled="disabled" :readonly="readonly" :id="id" :state="getValidationState(validationContext)" @input="handleInput" :value="value" :placeholder="placeholder"></b-form-input>
      <b-form-invalid-feedback :id="`${id}-feedback`">{{validationContext.errors[0]}}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import id from "vee-validate/dist/locale/id.json"
import { localize, extend, ValidationProvider } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";
import {BFormGroup,BFormInput,BFormInvalidFeedback} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('id', id)

export default {
  props:{
    label:{
      required: true,
      type: String
    },
    type:{
      required: false,
      type: String
    },
    size:{
      required: false,
      type: String
    },
    vid:{
      required: false,
      type: String
    },
    value:{
      required: false,
      type: [String,Number]
    },
    rules:{
      required: false,
      type: [Object,String]
    },
    readonly:{
      type: Boolean,
      default: false
    },
    disabled:{
      type: Boolean,
      default: false
    },
    placeholder:{
      type: String
    }
  },
  data(){
    return {
      id:""
    }
  },
  components:{
    BFormGroup,
    BFormInput,
    ValidationProvider,
    BFormInvalidFeedback,
  },
  methods:{
    handleInput(e){
      this.$emit('input', e)
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? (valid ? null : false) : null;
    },
  },
  created(){
    this.id = uuidv4()
  }
}
</script>

<style lang="scss">
  .padding-small {
    padding-left: 0px;
    padding-right: 5px;
  }
</style>