<template>
  <validation-provider :name="label||placeholder" :rules="rules" v-slot="validationContext">
    <b-form-group v-if="!inputGroup" :label="label" :label-for="id">
      <cleave
        ref="clive"
        :name="id"
        :readonly="readonly"
        :disabled="disabled"
        :class="['form-control',{'is-invalid': isInvalid(validationContext)},`form-control-${size}`]"
        :raw="true"
        :id="id"
        aria-describedby="invalid-feedback"
        :value="value"
        @input="handleInput"
        @keyup.native="$emit('keyup',$event)"
        :options="options">
      </cleave>
      <div class="invalid-feedback" v-if="isInvalid(validationContext)">
        {{validationContext.errors[0]}}
      </div>
    </b-form-group>
    <b-form-group v-else :label="label" :label-for="id">
      <b-input-group :size="size" :append="append">
        <cleave
          :name="id"
          ref="clive"
          :readonly="readonly"
          :disabled="disabled"
          :class="['form-control',{'is-invalid': isInvalid(validationContext)},`form-control-${size}`]"
          :raw="true"
          :id="id"
          :placeholder="placeholder"
          aria-describedby="invalid-feedback"
          :value="value"
          @input="handleInput"
          @keyup.native="$emit('keyup',$event)"
          :options="options">
        </cleave>
        <!-- <div class="invalid-feedback" v-if="isInvalid(validationContext)">
          {{validationContext.errors[0]}}
        </div> -->
      </b-input-group>
    </b-form-group>
  </validation-provider>
</template>

<script>
import idn from "vee-validate/dist/locale/id.json"
import { localize, extend, ValidationProvider } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";
import Cleave from 'vue-cleave-component'
import {BFormGroup,BInputGroup} from 'bootstrap-vue'
// import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'


Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('id', idn)
extend('minimal', {
  validate(value, {min}) {
    return parseFloat(value)>=parseFloat(min)
  },
  params:['min'],
  message: "minimal {_field_} adalah {min}"
})
extend('maximal', {
  validate(value, {min}) {
    return parseFloat(value)<=parseFloat(min)
  },
  params:['min'],
  message: "maksimal {_field_} adalah {min}"
})

export default {
  props:{
    label:{
      required: true,
      type: String
    },
    inputGroup:{
      type: Boolean,
      default: false
    },
    append:{
      type: String
    },
    size:{
      type: String,
      default:'md'
    },
    value:{
      required: false,
      type: [String,Number]
    },
    rules:{
      required: false,
      type: [Object,String]
    },
    readonly:{
      type: Boolean,
      default: false
    },
    disabled:{
      type: Boolean,
      default: false
    },
    placeholder:String
  },
  components:{
    Cleave,
    ValidationProvider,
    BFormGroup,
    BInputGroup
  },
  data(){
    return {
      options:{
        numeral:true,
        numeralThousandsGroupStyle:'thousand'
      },
      id: ""
    }
  },
  methods:{
    handleInput(e){
      this.$emit('input',e ? parseFloat(e) : e)
    },
    isInvalid({dirty,invalid}) {
      // console.log(validation)
      return dirty && invalid
    },
    keyup(e){
      this.$emit('keyup',e)
    }
  },
  created(){
    this.id = uuidv4()
  }
}
</script>

<style>

</style>